* {
  margin: 0;
  padding: 0;
  top: 0;
}

body {
  top: 0 !important;
  height: 100vh;
}

/* NavBars de google translate */
#btr {
  display: flex;
  height: 2.85em;
  overflow: hidden !important;
  border-radius: 5px;
  justify-content: 'center';


}

#btr .goog-te-combo {
  padding: 0.7em;
  cursor: pointer;
  border: 1px solid #666feb;
  border-radius: 5px;
  overflow: hidden;
  outline: none;
  width: 7em;
  overflow: hidden !important;
}

@media screen and (max-width:725px) {
  #btr .goog-te-combo {
    padding: 0.5em;
    width: 6em;
    overflow: hidden !important;
  }
}





.skiptranslate select {
  background-color: transparent !important;
}

.skiptranslate .VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}






/* .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc:=>  pour le spinner lors de la translation qui affiche*/
.VIpgJd-yAWNEb-hvhgNd {
  display: none;
}


.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}


/* Sloger */
.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* courant produit */
.ProduitsCournat .swiper-slide {
  position: relative;
  width: 4em;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ProduitsCournat .swiper-slide .containerImages {
  position: relative;
  width: 65px;
  height: 65px;
  border: 1px solid #666feb;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

.ProduitsCournat .swiper-slide .containerImages img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* courant hotel */
.HotelCournat .swiper-slide {
  position: relative;
  width: 18em;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.HotelCournat .swiper-slide .containerImages {
  position: relative;
  width: 15em;
  height: 15em;
  border: 1px solid #666feb;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}

.HotelCournat .swiper-slide .containerImages img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/* Titre  */
.strockText {
  -webkit-text-stroke: 2px firebrick;
  color: rgba(104, 255, 3, 0.836);

}

.stroketext1 {
  color: green;
  text-shadow: 1px -1px 0 white, 1px -1px 0 white, 1px -1px 0 white, 1px -1px 0 white;
}

.stroke-text {
  color: green;
  text-shadow: 1px -1px 0 white, 1px -1px 0 white, 1px -1px 0 white, 1px -1px 0 white;
}


/* animation pour zommer image */
@keyframes bounce-up-down {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce-up-down {
  animation: bounce-up-down 4s ease-in-out infinite;
}

.animation {
  animation: scaleTransform 3s infinite;
}

@keyframes scaleTransform {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}










/* bullietin */
/* Styles d'impression pour éviter les coupures */
@media print {
  .print-page-break {
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
}

.titre {
  font-family: "Nunito", sans-serif;
}

.hero-header img {
  animation: imgRotate 50s linear infinite;
}

@keyframes imgRotate {
  100% {
    transform: rotate(360deg);
  }
}

.Food {
  font-family: 'Pacifico', cursive;
}
.rs-drawer-body{
  padding:0px !important;
  margin:0px !important;
  display:flex !important;
  justify-content:center !important;
}